import $ from 'jquery';
//import { bodyScrollHandle, hideGlobalModal, checkPreviewMode } from '../components/onlyExport';
import * as func from '../components/onlyExport';

class TilesBB {
    constructor(){
        // this.removeAlertBanner = this.removeAlertBanner.bind(this);
        this.alterTwoCol = this.alterTwoCol.bind(this);
        this.start = this.start.bind(this);
        this.start();
    }

    start(){
        if(!func.checkPreviewMode()) return false;
        var _that = this; 
        if($(".global-tiles-container").length > 0){
            $(".global-tiles-container .tiles-component.two-column-container").each(function(){
                var thisE = $(this);
                _that.alterTwoCol(thisE);
                $(window).resize(func.debounce(function(){
                    _that.alterTwoCol(thisE);
                },200));
            });
            $(".global-tiles-container .tiles-component.three-column-container").each(function(){
                var thisE = $(this);
                _that.alterTwoCol(thisE, true);
                $(window).resize(func.debounce(function(){
                    _that.alterTwoCol(thisE, true);
                },200));
            });
        }
    }
    alterTwoCol($elem, isthreecol = false){
        var _phElem = $elem.find('.column-placeholders');
        var _columnW = $elem.find('.column-placeholders > div:first-child').data('col-w');
        var _childFirstElemH = $elem.find('.column-placeholders > div:first-child').data('col-h');
        var _childLastElemH = $elem.find('.column-placeholders > div:last-child').data('col-h');
        _phElem.css('grid-template-columns', _columnW+'% auto');
        
        var _compH = _phElem.height() - 24; // remove gap and calculate
        var reqH1 = (_compH * _childFirstElemH) / 100;
        var reqHLast = (_compH * _childLastElemH) / 100;
        let reqH2;
        if(isthreecol){
            var _childSecondElemH = $elem.find('.column-placeholders > div:nth-child(2)').data('col-h');
            var _columnSecondW = $elem.find('.column-placeholders > div:nth-child(2)').data('col-w');
            _phElem.css('grid-template-columns', _columnW+'% '+_columnSecondW+'% auto');
            reqH2 = (_compH * _childSecondElemH) / 100;
        }
        
        $elem.find('.column-placeholders > div:first-child > div:nth-child(1)').css('height',reqH1+'px');
        this.changeImg($elem.find('.column-placeholders > div:first-child > div:nth-child(1)'), reqH1);
        $elem.find('.column-placeholders > div:first-child > div:nth-child(2)').css('height',(parseFloat(_compH) - parseFloat(reqH1))+'px');
        this.changeImg($elem.find('.column-placeholders > div:first-child > div:nth-child(2)'), (parseFloat(_compH) - parseFloat(reqH1)));
        if(isthreecol){
            $elem.find('.column-placeholders > div:nth-child(2) > div:nth-child(1)').css('height',reqH2+'px');
            this.changeImg($elem.find('.column-placeholders > div:nth-child(2) > div:nth-child(1)'), reqH2);
            $elem.find('.column-placeholders > div:nth-child(2) > div:nth-child(2)').css('height',(parseFloat(_compH) - parseFloat(reqH2))+'px');
            this.changeImg($elem.find('.column-placeholders > div:nth-child(2) > div:nth-child(2)'), (parseFloat(_compH) - parseFloat(reqH2)));
        }
        $elem.find('.column-placeholders > div:last-child > div:nth-child(1)').css('height',reqHLast+'px');
        this.changeImg($elem.find('.column-placeholders > div:last-child > div:nth-child(1)'), reqHLast );
        $elem.find('.column-placeholders > div:last-child > div:nth-child(2)').css('height',(parseFloat(_compH) - parseFloat(reqHLast))+'px');
        this.changeImg( $elem.find('.column-placeholders > div:last-child > div:nth-child(2)'), (parseFloat(_compH) - parseFloat(reqHLast)))

    }
    changeImg($elem, imgH){
        if( $elem.find('img').length > 0)$elem.find('img').css('height',imgH+'px');
    }
}

export default TilesBB;