import $ from 'jquery';
//import * as allFunc from './newsroom';

export const autoHideDur = 2000;
export let afClose = false;


/* global functions export only *****/

export function bodyScrollHandle(isScroll = false){
    $('body').removeClass('noscroll');
    if(isScroll)$('body').addClass('noscroll');
}

export function hideGlobalModal(){
    $('.global-ikea-modal.show').removeClass('show');
    bodyScrollHandle(false);
}

/* End --- global functions export only *****/


export function afCloseGet(){
    return afClose;
}

export function afCloseSet(_b){
    afClose = _b;
}

export function curLoc(){
    var loc = window.location;
    return {
        fullUrl: loc.href,
        host: loc.host,
        hostname: loc.hostname,
        origin: loc.origin,
        pathname: loc.pathname,
        onlyUrl: loc.origin+loc.pathname,
        hash: loc.hash
    }
}

export function getScreenWidth(){
    return $(window).outerWidth();
}

export function getViewPortWidth(){
    return $(window).width();
}

export function getScrollWidth(){
    return window.innerWidth-$(document).width();
}

export function urlParam(name){
    let searchParams = new URLSearchParams(window.location.search);
    return {
        has: searchParams.has(name),
        get: searchParams.get(name)
    }
}

export function getBannersHeight(pos = null){
    let bannerH = 0;
    if($(".event-banner").length == 1){
        bannerH = $(".event-banner").outerHeight();
    }
    $('.ikea-header-banner').each(function(){
        bannerH += $(this).outerHeight();
    });
    if(pos == 'sticky'){
        bannerH += $(".navbar__main-bar").outerHeight();
    }
    return bannerH;
}

export function hexc(colorval) {
    var parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    delete(parts[0]);
    for (var i = 1; i <= 3; ++i) {
      parts[i] = parseInt(parts[i]).toString(16);
      if (parts[i].length == 1) parts[i] = '0' + parts[i];
    }
    return '#' + parts.join('');
}

export function debounce(func, time){
    var time = time || 200;
    var timer;
    return function(event){
        if(timer) clearTimeout(timer);
        timer = setTimeout(func, time, event);
    };
}

export function bytesConvertion(bytes){
    if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed() + " MB"; }
    else { bytes = (bytes / 1024).toFixed() + " KB"; }
    return bytes;
}

export function browserType(){
    if ( navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1 ) {
        return 'Edge';
    }
    else if( navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1 )
    {
        return 'Opera';
    }
    else if( navigator.userAgent.indexOf("Chrome") != -1 )
    {
        return 'Chrome';
    }
    else if( navigator.userAgent.indexOf("Safari") != -1)
    {
        return 'Safari';
    }
    else if( navigator.userAgent.indexOf("Firefox") != -1 ) 
    {
        return 'Firefox';
    }
    else if( ( navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true ) ) //IF IE > 10
    {
        return 'IE';
    }  
    else 
    {
        return 'unknown';
    }
}

export function downloadAsset(assetUrl,fName){
	fetch(assetUrl, {
		method: 'GET',
	})
	.then(resp => resp.blob())
	.then(blob => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = fName;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	})
}

export function checkPreviewMode(){
    var isPreview = curLoc().fullUrl.search("sc_mode=edit");
    if(isPreview == -1)return true; else return false;
}

export function copyToClipboard(str){
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(str).select();
    document.execCommand("copy");
    $temp.remove();
    return true;
}

export function deviceDimensions(){
    var dw = $( window ).width();
    var dh = $( window ).height();
    return { winWidth: dw, winHeight: dh};
}

export function deviceMode(){
    var ww = deviceDimensions().winWidth;
    switch (true)
    {
        case (ww < 576):    return 'mobile';    // 0 - 575px -- mobile
        case (ww >= 576 && ww < 992):   return 'tablet';    // 576 - 991 -- Tablet
        default:    return 'desktop';   // 992 -     -- Desktop
    }
}

export function elementDimensions($this){
    var device = deviceDimensions();
    var pos = $this.position();
    var eleW = $this.width();
    var right = device.winWidth - (pos.left + eleW);
    return {left: pos.left, top: pos.top, right: right};
}

export function checkImageExists(imageUrl, callBack) {
    var imageData = new Image();
    imageData.onload = function() {
        callBack(true);
    };
    imageData.onerror = function() {
        callBack(false);
    };
    imageData.src = imageUrl;
}

export function callAjax(url,data,dataType,type,callback){
    $.ajax({
        type : type,
        //cache : false,
        url : url,        
        data: data,
        dataType : dataType,
        xhrFields: { withCredentials: true },
        success : function(data){
            callback(true,data);
        },
        error : function(jqXHR, exception) {
            //console.error('Something went wrong...');
            callback(false,'<h3>Error: '+jqXHR.statusText+' ['+jqXHR.status+'] </h3>');
        }
    });
}

export function isValidEmailAddress(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

export function isTextLengthValidate(txt) {
    var txtValidate = txt.length >=30 && txt.length <=1000;
    return txtValidate; 
}