import $ from 'jquery';
import { bodyScrollHandle, hideGlobalModal, checkPreviewMode } from '../components/onlyExport';

class ProfileCard {
    constructor(){
        // this.removeAlertBanner = this.removeAlertBanner.bind(this);
        this.showModal = this.showModal.bind(this);
        this.appendContent = this.appendContent.bind(this);
        this.start();
    }

    start(){
        var _that = this; 
        if($(".global-profile-card").length > 0){
            $(".global-profile-card > .component-content > a").each(function(){
                $(this).attr('href','javascript:void(0)');
            });
            $(".global-profile-card picture, .global-profile-card .pc-content").click(_that.showModal);
        }
    }
    showModal(elem){
        if(!checkPreviewMode())return;
        $(elem.currentTarget).siblings('.global-ikea-modal').addClass('show');
        bodyScrollHandle(true);
        this.appendContent($(elem.currentTarget).parent());
        
    }
    appendContent($e){
        $('.global-ikea-modal.show .modal-body').html('<div class="pc-details"></div>');
        $( $e.find('picture').clone() ).appendTo('.global-ikea-modal.show .modal-body .pc-details');
        $( $e.find('.pc-content-tablet').clone() ).appendTo('.global-ikea-modal.show .modal-body .pc-details');
        $( $e.find('.pc-content').clone() ).appendTo('.global-ikea-modal.show .modal-body .pc-details');
        $(".global-ikea-modal").unbind('click');
        $(".global-ikea-modal").click(function(e){
            e.stopPropagation();
            if($(e.target).hasClass('global-ikea-modal show') || $(e.target).hasClass('hover-effect'))
            hideGlobalModal();
        });
    }
}

export default ProfileCard;