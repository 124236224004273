import $, {trim } from 'jquery';
import * as func from '../components/onlyExport';


export function showToast(message){
    var toastMsg = $('<div class="toast"> <span> ' + message + ' </span> <span class="close-toast"><div class="hover-effect"></div><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.00057 6.4148L7.82887 9.24311L9.24308 7.8289L6.41478 5.00058L9.24308 2.17227L7.82886 0.758057L5.00057 3.58636L2.17203 0.757812L0.75782 2.17203L3.58636 5.00058L0.757812 7.82914L2.17202 9.24336L5.00057 6.4148Z" fill="white"/></svg> </span> </div>');
    $('body').append(toastMsg);
    toastMsg.fadeIn();
    toastMsg.find('.close-toast').on('click', function(){
        toastMsg.fadeOut(function(){
            $(this).remove();
        });
    });
    setTimeout(function(){
        toastMsg.fadeOut(function(){
            $(this).remove();
        });
    }, 2000)
}

$(document).on('click','.figure-image-with-hotspot .ikea-modal-dialog a.close',function(e){
    closeModalDialog();
});

export function initIkeaModal(){
    $(document).ready(function() {

        $(".figure-image-with-hotspot .ikea-modal-dialog .close").click(function(e){
            e.preventDefault();            
            closeModalDialog();
        });
       
    });
    $(document).on('keyup', function(event) {
        if (event.key == "Escape") {
            closeModalDialog();
            closeImgPopup();
        }
    });
    $(document).on('click','.figure-image-with-hotspot .ikea-modal-dialog',function(e){
        closeModalDialog();
    });
    $(document).on('click','.figure-image-with-hotspot .ikea-modal-dialog > div > *',function(e){
        e.stopPropagation();
    });
    $(document).on('click','.figure-image-with-hotspot .ikea-modal-dialog > .close',function(e){
        closeModalDialog();
    });
    $(document).on('click','.figure-image-with-hotspot .ikea-img-download-popup .close',function(e){
        e.preventDefault();
        closeImgPopup()
       
    });
}
function closeModalDialog(){    
    $(".figure-image-with-hotspot .ikea-modal-dialog:not(\".side-panel\") .modal-body").html('');
    $(".figure-image-with-hotspot .ikea-modal-dialog.side-panel").removeClass("showSheet").addClass("hideSheet"); 
    setTimeout(function(){
        $(".figure-image-with-hotspot .ikea-modal-dialog.side-panel").hide();
        closeBtn();
    },300);
    $(".figure-image-with-hotspot .ikea-modal-dialog:not('.side-panel')").hide();
    $("body").css({"overflow":"auto","padding-right":"0px"});
    window.history.replaceState(null, null, func.curLoc().onlyUrl); 
    $("a.bubble.active").each(function(){
        $(this).removeClass('active');
        $(this).siblings().removeClass('inactive');
        var _tl = 'top: '+$(this).attr('data-top')+';left: '+$(this).attr('data-left');
        var _style = _tl;
        $(this).attr('style',_style);
    });
   
}
//Option to image download US
export function getHref(_this, e){
    e.preventDefault();
    var hrefValue = _this.attr('href');
    var imageNameWithQuery = hrefValue.substring(hrefValue.lastIndexOf('/') + 1);
    var imageName = imageNameWithQuery.split('?')[0];
    var imgFormates = /\.(jpg|jpeg|png|gif|svg)(\?.*)?$/i;
    var newImageName;
    if(imgFormates.test(imageName)){
        newImageName = imageName.replace(/-\w+\./, '.');
    }else{
        newImageName = imageName;
    }
    
    //_this.attr('href', hrefValue.replace(imageNameWithQuery, newImageName));
    //_this.attr("download", newImageName);

    /* new function for Content-Disposition overriding download image */
     fetchDownloadBlob(hrefValue, newImageName);
}
//Fetch the image as a blob to bypass the Content-Disposition header and trigger download
function fetchDownloadBlob(hrefValue, newImageName){
    fetch(hrefValue)
    .then(response => {
        if(!response.ok){
            throw new Error(`HTTP error Status: ${response.status}`)
        }
        return response.blob();
    })
    .then(blob => {
        //create temp blob url
        const blobUrl = URL.createObjectURL(blob);
        //temp anchor tag
        const tempLink = document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.download = newImageName;
        //Append link to DOM
        document.body.appendChild(tempLink);
        //click the link to trigger
        tempLink.click();
        document.body.removeChild(tempLink);
        //Remove blob url to free memory
        URL.revokeObjectURL(blobUrl);
    })
    .catch(error => console.error('Error downloading:',error));
};
export function closeImgPopup(){    
    $(".ikea-img-download-popup").hide();
    $("body").css({"overflow":"auto","padding-right":"0px"});
}
function closeBtn(){
    $('.figure-image-with-hotspot .ikea-modal-dialog.side-panel').find('.image-download-info').hide();
}
$(document).on('click','.figure-image-with-hotspot .imgDownload-dialog.ikea-img-download-popup',function(e){
       if($(this).hasClass('imgDownload-dialog') === $(e.target).hasClass('imgDownload-dialog')){
            closeImgPopup();
       }
});
$(document).on('click','.imgDownload-dialog.ikea-img-download-popup',function(e){
    e.stopPropagation();
});
export function imageDownload(){
    $(document).ready(function() {
        $('.figure-image-with-hotspot.show-download-btn:not(\".slide-show, .serp-page\")').each(function(indx){
            var indx = indx;
            var _that = $(this);
            var hasSPanel = $(this).parents('.ikea-modal-dialog').hasClass('side-panel');
              _that.find('.figure-image .figure-image__download a.download').click(function(e){
                if(hasSPanel == true){
                    _that.find('.imgDownload-dialog').removeClass('ikea-img-download-popup').addClass("showContent");
                    _that.find(".imgDownload-dialog .modal-body").removeClass('modal-body');
                    _that.find(".imgDownload-dialog .modal-header").hide();
                }
                if(localStorage.getItem('useRestriction') === 'true'){
                    getHref($(this), e);
                    showToast('Downloading...');
                }else{
                    e.preventDefault();
                    if(hasSPanel == true){
                        _that.find(".imgDownload-dialog").show().css("display","block");
                        _that.find(".imgDownload-dialog  .image-download-info").show().css("display","block");
                    }else{
                        _that.find(".ikea-img-download-popup.imgDownload-dialog").show().css("display","flex");
                        $("body").css('overflow','hidden');
                    }
                }
                    
                 _that.find('.figure-image .figure-image__download > .imgDownload-dialog .btn-down > a').click(function(e){
                    var isCheck = _that.find('.figure-image .figure-image__download > .imgDownload-dialog .gdpr-text #donotAsk').is(':checked');
                    if(isCheck === true){
                        localStorage.setItem('useRestriction', true);
                    }
                    getHref($(this),e);
                    showToast('Downloading...');
                    if(hasSPanel == true){
                      closeBtn();
                    }else{
                        closeImgPopup();
                    }
                });
            });
    
            $(this).find(".imgDownload-dialog .backArrow").click(function(){
                closeBtn();
            });
       });
    });
};
