import $ from 'jquery';

class AlertBanner {
    constructor(){
        this.removeAlertBanner = this.removeAlertBanner.bind(this);
        this.showAlertBanner = this.showAlertBanner.bind(this);
        this.setSession = this.setSession.bind(this);
        this.start();
    }


    // Get namespace for session storage based on the first meaningful segment of the path
    getNamespace() {
        const path = window.location.pathname; // Get the full path
        const segments = path.split('/').filter(Boolean); // Split into parts and remove empty segments
        if (segments.length >= 2) {
            return `alert_ban_${segments[0]}_${segments[1]}`; // Use the first two segments (e.g., /en/home)
        }
        return 'alert_ban_default'; // Fallback key if the structure doesn't match
    }

    start(){
        var _that = this; 
        if($(".global-alert-banner").length > 0){
            const namespace = _that.getNamespace();
            if( typeof(sessionStorage[namespace]) == 'undefined' || sessionStorage[namespace] == 'on' ){
                _that.showAlertBanner();
            }
            $(".global-alert-banner .global-alert-close").click(_that.removeAlertBanner);
        }
    }
    setSession(boolVal){
        const namespace = this.getNamespace();
        sessionStorage[namespace] = boolVal;
    }
    showAlertBanner(){
        this.setSession("on");
        $(".global-alert-banner").addClass("show");
		$('.mobile-responsive-menu').css("top", "60px");
		$('.navbar.js-navbar').css("top", "42px");
        $('#wrapper').css("padding-top", "42px");
    }
    removeAlertBanner(){
        this.setSession("off");
        $(".global-alert-banner").removeClass("show");
		$('.navbar.js-navbar').css("top", "0px");
		$('.mobile-responsive-menu').css("top", "16px");
        $('#wrapper').css("padding-top", "0px");
    }
}

export default AlertBanner;