import 'raf/polyfill';
import $ from 'jquery';
import * as func from './components/onlyExport';
import { hotspotBubbleFun, showBubbleOnEditorGlobal } from './globalcomponents/global-hotspot-on-image';
import { imageDownload, initIkeaModal } from './globalcomponents/global-imgDownload';
import AlertBanner from './globalComponents/alert-banner';
import ProfileCard from './globalComponents/global-profile-card';
import HtmlClick from './globalComponents/global-click-handle';
import TilesBB from './globalComponents/global-tiles-bb';



$(document).ready(function(){
    initIkeaModal();
    hotspotBubbleFun();
    imageDownload();
    const objAb = new AlertBanner();
    const objPc = new ProfileCard();
    const objTiles = new TilesBB();
    if(window.location.href.indexOf("sc_mode=edit") > -1) {
        setTimeout(showBubbleOnEditorGlobal,1000);
    };
    const onjClicks = new HtmlClick();
});