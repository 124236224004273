import $, { type } from 'jquery';
import { isElemInViewPort } from '../components/isInViewPort';
import * as func from '../components/onlyExport';

export function hotspotBubbleFun(){
    $(document).ready(function() {
        if(func.checkPreviewMode()){
            $(".inline-side-panel").each(function(){
                if( typeof $(this).attr('href') != 'undefined'){
                    var _getPanelId = ($(this).attr('href')).replace(/#/g, "");
                    $(this).attr('data-panelid',_getPanelId);
                }
            });
            const objPulseBubble = new HotspotBubble();
        } else {
            $(".inline-side-panel").each(function(){
                if( typeof $(this).attr('data-panelid') != 'undefined'){
                    var _getPanelId = $(this).attr('data-panelid');
                    $(this).attr('href',_getPanelId);
                }
            });
            $('.side-panel-content').css('display','block');
            if(func.curLoc().origin == 'https://tst.about.ikea.com'){            
                var fileref = document.createElement("link");
                fileref.rel = "stylesheet";
                fileref.type = "text/css";
                fileref.href = "https://tst.about.ikea.com/-/media/Themes/NL/Master%20site/Master/Experience%20Editor%20Styles/experience-aboutikea.css";
                document.getElementsByTagName("head")[0].appendChild(fileref);
            }
        }
    });
}

class HotspotBubble {
    constructor(){          
        this.showHotSpotSidePanel = this.showHotSpotSidePanel.bind(this); 
        this.changeHotSpotBubble = this.changeHotSpotBubble.bind(this);
        this.start = this.start.bind(this);
        this.autoScroll = this.autoScroll.bind(this);
        $(window).on('resize scroll',this.start); 
        this.start('ready');    
    }

    changeHotSpotBubble($elem){
        var t = $elem.attr('data-top');
        var l = $elem.attr('data-left');
        var _tl = 'top: '+t+';left: '+l;
        var _style = "";
        _style = _tl; 
        $elem.attr('style',_style);
        //return true;
    }

    start(isReady){
        var _that = this;
        if(isReady == 'ready'){
            $(".figure-image-with-hotspot.pulsating-bubble").each(function(){
                //$(this).addClass('on-preview');
                var _thisEl = $(this);
                $(this).find('a.bubble').each(function(){
                    var _sty = $(this).attr('style');
                    var getCss = _sty.replace(/top: /g, "").replace(/left: /g, "").replace(/background: /g, "");
                    var _getAttr = getCss.split(';');
                    $(this).attr('data-top',_getAttr[0]);
                    $(this).attr('data-left',_getAttr[1]);
                    $(this).attr('style','top: '+_getAttr[0]+';left: '+_getAttr[1]);
                    $(this).click(function(){
                        $(this).addClass('active');
                        _that.changeHotSpotBubble($(this));
                        _that.showHotSpotSidePanel($(this));
                        mapOnViewport(_thisEl);
                    }); 
                });                               
            });

            /* This code need to add in local file not global file then only slide panel will work */

            //   $(".ikea-modal-dialog.side-panel").each(function(){
            //     var _next = $(this).next().clone();
            //     console.log(_next);
            //     var getId = _next.find('.panelid span').text();
            //     console.log(getId);
            //     $(this).attr('id','panel-'+getId);
            //     if(_next.length == 1){
            //         $(this).find('.modal-body').html(_next);
            //         $(this).next().remove();
            //     }                              
            //     if( $("a[data-panelid='"+getId+"']").length > 0){
            //         var _pHtml = $("#panel-"+getId).clone();                    
            //         $("#panel-"+getId).parent().parent().addClass('no-gap');
            //         $("#panel-"+getId).remove();
            //         $(_pHtml).insertAfter( $("a[data-panelid='"+getId+"']") );
            //     } 
            // });
            //scroll to map on viewport
            function mapOnViewport(Elthis){
                var scrollTopEl = Elthis.offset().top - 100;
                $('html, body').animate({
                    scrollTop:scrollTopEl
                },500);
                $('body').css('overflow','auto');
                $('body').css('overflow','hidden');
            }
            if( func.urlParam("panelID").has )
                if(func.urlParam("panelID").get.length > 0){
                    _that.showHotSpotSidePanel($(this), func.urlParam("panelID").get);
                    //on page load bubble map set to viewport -sp156 
                    $(".figure-image-with-hotspot.pulsating-bubble").each(function(){
                        var hasActiveBubble = $(this).find('a.bubble').hasClass('active');
                        var parentThis = $(this).find('a.bubble.active').parent().parent();
                        if(hasActiveBubble){
                            mapOnViewport(parentThis)
                        }
                    });
                }
        }       
        $(".figure-image-with-hotspot.pulsating-bubble").each(function(){
            if( isElemInViewPort($(this),3) ){
                $(this).addClass('viewed');
                setTimeout(() => {
                    $(this).find('a.bubble').each(function(i){
                        var $bubble = $(this);
                        setTimeout(function(){
                            $bubble.addClass('pulse');                   
                            setTimeout(() => {                            
                                 _that.changeHotSpotBubble($bubble);
                                $bubble.addClass('extra-pulse');
                            }, 400);
                        },i * 300);
                    });
                }, 500);                
            }
        });
       
    }
    showHotSpotSidePanel($elem, $pid = null){        
        var panelId= ($pid != null) ? $pid : $elem.data('panelid');
        if($("#panel-"+panelId).length == 0){
            console.error('No side panels found');
            $elem.removeClass('active');
            this.changeHotSpotBubble($elem);
            return;
        }        
        window.history.replaceState(null, null, "?panelID="+panelId);
        $("#panel-"+panelId).removeClass("hideSheet").show().addClass('showSheet');
        if($("#panel-"+panelId).hasClass('showSheet')){
            $(".figure-image-with-hotspot .ikea-modal-dialog.side-panel.showSheet").prev('a.bubble').addClass('active').siblings().addClass('inactive');
        }else{
            $(".figure-image-with-hotspot .ikea-modal-dialog.side-panel.hideSheet").prev('a.bubble').removeClass('active').siblings().removeClass('inactive');
        }
        $(".figure-image-with-hotspot .ikea-modal-dialog > div, .ikea-modal-dialog .modal-body").stop().animate({scrollTop: 0},500);
        $("body").css({"overflow":"hidden","padding-right": func.getScrollWidth()+"px"});
        $('.figure-image-with-hotspot .ikea-modal-dialog.side-panel.showSheet .modal-body').find('a').last().on('blur', function() {
            $('.figure-image-with-hotspot .ikea-modal-dialog.side-panel.showSheet a.close').focus();
        });
        if($pid != null){
            this.autoScroll($pid);
            func.afCloseSet(true);
        }
    }
    autoScroll(pid){
        const scrollLocation = $("a[data-panelid="+pid+"]:eq(0)").offset().top;
        $('html,body').stop().animate({scrollTop: scrollLocation - 100 - (func.getBannersHeight())}, 500);
    }
}




//bubble map coordinates analysis
export function showBubbleOnEditorGlobal(){
        $(".figure-image-with-hotspot").each(function(){
            var $el = $(this);
            var $Elwidth = $(this).find('img').width();
            var $ElHeight = $(this).find('img').height();
            $(this).find('img').mousemove(function(event){
                var offset = $(this).offset();
                var x = event.pageX - offset.left;
                var y = event.pageY - offset.top;
                var xValue = pixelToPercentageGlobal(x, $Elwidth);
                var yValue = pixelToPercentageGlobal(y, $ElHeight);
                $el.find('.bubble-tooltip').text('X: ' + parseInt(xValue)+ ' ' + 'Y: ' + parseInt(yValue));
                $el.find('.bubble-tooltip').css({
                    left:x,
                    top: y,
                    display:'block',
                })
            });
            $(this).find('img').mouseleave(function(){
                $el.find('.bubble-tooltip').css('display', 'none');
            });
        });
};
function pixelToPercentageGlobal(pxValue,ElWidthHeight){
    if(ElWidthHeight <=0){
        return 0;
    }
   return (pxValue / ElWidthHeight) * 100;
}

 