import $ from 'jquery';
import { checkPreviewMode } from '../components/onlyExport';

class HtmlClick{
    constructor(){
        this.start();
    }
    start(){
        $(document).on('keydown', function(event) {
            if (event.key === "Escape" || event.keyCode === 27) {
                //if($('.global-ikea-modal.show').length > 0)hideGlobalModal();
            }
        });        
        // for experience editor
        if(!checkPreviewMode()){  
            if( $(".global-profile-container").length > 0){
                $(".global-profile-container").addClass('max-w-center');
            }
        }
    }
}

export default HtmlClick;